<!-- 编辑的正式账户组件 -->
<template>
  <div class="official_account_edit margin_top_20">
    <!-- 选中按钮 -->
    <i
      class="margin_top_7"
      v-if="normal.isShow"
      @click.stop="doClick"
      :class="['margin_top_2', 'margin_left_10', 'check_el', 'iconfont', normal.choosed ? 'icon-xuanzhong' : 'icon-danxuanweixuanzhong']"
    ></i>

    <span class="margin_top_6" :class="{ margin_left_10: !normal.isShow }">正式账户</span>

    <!-- 课程级别和子级别操作区域 -->
    <div class="operate_area margin_left_20">
      <!--  所有级别以及时间设定 -->
      <div class="all_level">
        <!-- 所有级别选中按钮 {{normal.label}}  -->
        <el-checkbox class="margin_top_6" :disabled="!normal.choosed" v-model="normal.selected" @change="chooseAllAction">所有级别</el-checkbox>

        <!-- 所有级别的内容部分 -->
        <div class="all_level_content">
          <!-- 所有级别教师账号部分 -->
          <div class="teacher_account">
            <!-- 所有级别的输入的教师有效账户 -->
            <span class="margin_left_20 tv_red">*</span><span>有效教师账户数：</span>

            <el-input
              maxlength="3"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              :disabled="!normal.selected"
              clearable
              v-model.number="normal.teacherNum"
              placeholder="请输入账户数"
            ></el-input>

            <!-- 所有级别的账户有效期 -->
            <span class="margin_left_20 tv_red">*</span><span>账户有效期：</span>

            <!-- 所有级别的教师账户有效期时间选择 -->
            <CommonDatePicker v-model="normal.time" :disabledDate="!normal.selected"></CommonDatePicker>
          </div>

          <!-- 所有级别学生账号部分 -->
          <div class="stuent_account margin_top_20" v-if="pid == 4 || pid == 5 || pid == 9 || pid == 12">
            <!-- 所有级别的输入的学生有效账户 -->
            <span class="margin_left_20 tv_red">*</span><span>有效学生账户数：</span>

            <el-input
              maxlength="3"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              :disabled="!normal.selected"
              clearable
              v-model.number="normal.studentNum"
              placeholder="请输入账户数"
            ></el-input>

            <!-- 所有级别的账户有效期 -->
            <span class="margin_left_20 tv_red">*</span><span>账户有效期：</span>

            <!-- 所有级别的学生账户有效期时间选择 v-model="normal.studentTime" -->
            <CommonDatePicker v-model="normal.studentTime" :disabledDate="!normal.selected"></CommonDatePicker>
          </div>
        </div>
      </div>

      <!-- 子级别以及时间设定 -->
      <div class="child_level margin_left_20 margin_top_20">
        <div class="item_child_level" v-for="(item, index) in normal.levelArray" :key="index">
          <!-- 选中按钮  -->
          <el-checkbox class="margin_top_4" :title="item.label" :disabled="!normal.choosed" v-model="item.selected" @change="doClickItem(item)">
            {{ item.label }}
          </el-checkbox>

          <!-- 子级别的选项 -->
          <div class="item_level_content">
            <!-- 教师账号部分 -->
            <div class="item_level_teacher_account">
              <!-- 子级别的输入的教师有效账户 -->
              <span class="margin_left_20 tv_red">*</span><span>有效教师账户数：</span>

              <el-input
                maxlength="3"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                :disabled="!item.selected"
                clearable
                v-model.number="item.teacherNum"
                placeholder="请输入账户数"
              ></el-input>

              <!-- 子级别的老师账户有效期 -->
              <span class="margin_left_20 tv_red">*</span><span>账户有效期：</span>

              <!-- 子级别的教师账户有效期时间选择   -->
              <CommonDatePicker v-model="item.time" :disabledDate="!item.selected"></CommonDatePicker>
            </div>

            <!-- 学生账号部分 -->
            <div class="item_level_student_account margin_top_20" v-if="pid == 4 || pid == 5 || pid == 9|| pid == 12">
              <!-- 子级别的输入的学生有效账户 -->
              <span class="margin_left_20 tv_red">*</span><span>有效学生账户数：</span>

              <el-input
                maxlength="3"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                :disabled="!item.selected"
                clearable
                v-model.number="item.studentNum"
                placeholder="请输入账户数"
              ></el-input>

              <!-- 子级别的学生账户有效期 -->
              <span class="margin_left_20 tv_red">*</span><span>账户有效期：</span>

              <!-- 子级别的学生账户有效期时间选择  -->
              <CommonDatePicker v-model="item.studentTime" :disabledDate="!item.selected"></CommonDatePicker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 日期选择器
import CommonDatePicker from "@/components/CommonDatePicker.vue";
//引入混入
import EditProduct from "@/mixin/EditProduct.js";

export default {
  mixins: [EditProduct],
  data() {
    return {};
  },

  created() {},
  methods: {
    //正式账号的点击事件
    doClick() {
      //改变选中状态
      this.normal.choosed = !this.normal.choosed;
      this.$emit("doChoosedAccount", 1);
    },

    //所有级别的选中事件
    chooseAllAction() {
      //所有级别选中,需要将所有子级别不选中并且还原子级别的状态
      if (this.normal.selected) {
        //还原所有子级别状态
        this.clearChildState();
      } else {
        //还原所有级别的状态
        this.clearAllState();
      }
    },

    //子级别的选中事件
    doClickItem(item) {
      if (item.selected) {
        //选中
        //还原所有级别的状态
        this.clearAllState();
      } else {
        //取消
        //还原单个子级别的状态
        this.clearItemState(item);
      }
    },
  },

  computed: {},
  components: {
    CommonDatePicker,
  },
  //pid用于控制编辑正式账户是否显示编辑学生账户,只有pid=4程序蛙少儿编程和pid=5模考中心才显示编辑学生
  props: ["normal", "pid"],
};
</script>
<style lang="scss" scoped>
.official_account_edit {
  display: flex;
  padding-bottom: 30px;
  // 选中按钮
  .check_el {
    color: #409eff;
    margin-right: 6px;
    cursor: pointer;
  }
  .operate_area {
    flex: 1;

    //所有级别
    .all_level {
      display: flex;
      justify-items: start;

      //所有级别内容部分
      .all_level_content {
        //所有级别教师账号部分
        .teacher_account {
          display: flex;
          align-items: center;
        }
      }
    }

    //子级别展示区域
    .child_level {
      min-height: 140px;
      max-height: 300px;
      overflow-y: auto;
      margin-right: 14px;

      //子级别的条目
      .item_child_level {
        margin-right: 16px;
        margin-bottom: 20px;
        display: flex;
        ::v-deep .el-checkbox__label {
          max-width: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-spacing: nowrap;
          vertical-align: middle;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #f5f5f5;
  }

  //定义滚动条轨道  内阴影+圆角
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  //定义滑块内阴影+圆角
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 12px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.01);
    background-color: #409eff;
  }

  ::v-deep .el-range-editor {
    width: 260px;
  }

  ::v-deep .el-input__inner {
    height: 28px;
    line-height: 28px;
    padding-left: 6px;
  }

  ::v-deep .el-input__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ::v-deep .el-input {
    width: 120px;
    font-size: 12px;
  }

  ::v-deep .el-input__suffix {
    right: 0px;
  }
}
</style>
