// 日历封装组件 // v-model="searchDateRange" // v-bind:disabledDate="false"
<template>
  <el-date-picker
    unlink-panels
    v-model="timevalue1"
    :picker-options="pickerOptions"
    type="daterange"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    range-separator="至"
    align="right"
    size="mini"
    :disabled="disabledDate"
    @change="changeTime"
  />
</template>

<script>
export default {
  name: "CommonDatePicker",

  model: {
    prop: "timevalue",
    event: "change",
  },

  created() {},

  data() {
    return {
      timevalue1: [],
      pickerOptions: {
        disabledDate(time) {
          //一年365天
          const oneYear = 365 * 24 * 60 * 60 * 1000;
          return time.getTime() < Date.now() - 8.64e7 || time.getTime() > Date.now() + oneYear;
        },
      },
    };
  },

  watch: {
    timevalue: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.timevalue1 = val;
        }
        if (val != null) {
          this.$emit("change", val);
        } else {
          this.$emit("change", []);
        }
      },
    },
    timevalue1: {
      immediate: true,
      // console.log(val, 'time')
      handler(newVal) {
        if (newVal != null) {
          this.$emit("change", newVal);
        } else {
          this.$emit("change", []);
        }
      },
    },
  },

  methods: {
    getData(time) {
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return y + "-" + m + "-" + d;
    },

    changeTime(val) {
      // var oval = this.moment(val).format('YYYY-MM-DD HH:mm:ss')
      // console.log(val)
      if (val == null) {
        this.$emit("change", []);
      } else {
        this.$emit("change", val);
      }
    },
  },

  props: {
    disabledDate: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },

    timevalue: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
  },
};
</script>
<style lang="scss" scoped></style>
