export default {
  methods: {
    //格式化时间
    getPostData(time) {
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return y + "-" + m + "-" + d;
    },

    //还原试用账户状态的方法
    clearTiralState() {
      if (!this.trial.choosed) {
        //如果试用范围是打开状态,进行关闭
        this.$refs.try_account_edit.close();

        //试用账户的试用教师账户数
        this.trial.teacherNum = "";
      }
    },

    //还原正式账户状态的方法
    clearNormalState() {
      if (!this.normal.choosed) {
        //如果正式账号非选中,则需要还原所有正式账号的数据为初始状态
        this.clearAllState();

        //还原所有子级别状态
        this.clearChildState();
      }
    },

    //还原所有所及的状态
    clearAllState() {
      this.normal.selected = false;

      //还原所有级别的有效账户老师数
      this.normal.teacherNum = "";

      //还原所有级别的老师账户有效期
      this.normal.time.splice(0, this.normal.time.length);
      if (this.pid == 4 || this.pid == 9 || this.pid == 12) {
        // pid=4 和 pid=5  程序蛙少儿编程和悟空寻宝
        //还原学生账户数
        this.normal.studentNum = "";

        //还原所有级别的学生账户有效期
        this.normal.studentTime.splice(0, this.normal.studentTime.length);
      }
    },

    //还原所有子级别的状态
    clearChildState() {
      //还原子级别
      let levelArray = this.normal.levelArray;
      levelArray.forEach(item => {
        this.clearItemState(item);
      });
    },

    //还原单个子级别条目状态的方法
    clearItemState(item) {
      //子级别的选中状态
      item.selected = false;
      //还原子级别的有效账户老师数
      item.teacherNum = "";
      //还原子级别的选中时间,默认空数组
      item.time.splice(0, item.time.length);
      if (this.pid == 4 || this.pid == 9 || this.pid == 12) {
        // pid=4 和 pid=5  程序蛙少儿编程和悟空寻宝
        //还原学生账户数
        item.studentNum = "";
        //还原所有级别的学生账户有效期
        item.studentTime.splice(0, item.studentTime.length);
      }
    },

    //判断新增账户完整性,pid用于控制判断试用账的户学生数 和正式账户的学生账户数
    //pid=4 程序蛙少儿编程 才需要判断使用账户学生数
    //pid=4 和 pid=5 模考 才需要判定正式账户的学生数
    judgeData(pid) {
      if (!this.isEmpty(this.trial) && !this.isEmpty(this.normal)) {
        //试用账户和正式账户都显示
        if (!this.trial.choosed && !this.normal.choosed) {
          //试用账户和正式账户都没选中
          this.showWarnMsg("未设置任何相关数据");
          return false;
        } else if (this.trial.choosed) {
          //试用账户选中
          //是否输入试用教师账户数
          // if (this.trial.teacherNum == 0) {
          //   this.showWarnMsg("输入试用教师账户数必须大于0");
          //   return false;
          // }
          if (this.isEmpty(this.trial.teacherNum)) {
            this.showWarnMsg("未输入试用教师账户数");
            return false;
          }
          //是否输入试用学生账户数, 目前只有程序蛙pid=4和pid==9悟空寻宝才需要判定试用账户的输入学生数
          if (pid == 4 || pid == 9 || pid == 12) {
            // if (this.trial.studentNum == 0) {
            //   this.showWarnMsg("输入试用学生账户数必须大于0");
            //   return false;
            // }

            if (this.isEmpty(this.trial.studentNum)) {
              this.showWarnMsg("未输入试用学生账户数");
              return false;
            }
          }
        } else if (this.normal.choosed) {
          // 正式账户选中
          //判断正式账户的数据完整性
          return this.judgeNormal(pid);
        }
      } else {
        //只有正式账户显示
        //判断正式账户的数据完整性
        return this.judgeNormal(pid);
      }

      return true;
    },

    //判断正式账户数据
    judgeNormal(pid) {
      if (!this.normal.selected && !this.childLevelHasSelected()) {
        //所有级别和子级别都没选有选中
        // this.showWarnMsg(`未设置正式账户的级别数据`)
        this.showWarnMsg(`未设置任何相关数据`);
        return false;
      } else if (this.normal.selected) {
        //所有级别选中

        //有效教师账户数
        // if (this.normal.teacherNum == 0) {
        //   this.showWarnMsg("输入所有级别的有效教师账户数必须大于0");
        //   return false;
        // }

        if (this.isEmpty(this.normal.teacherNum)) {
          this.showWarnMsg("未输入所有级别的有效教师账户数");
          return false;
        }

        //教师账户有效期
        if (this.normal.time.length == 0) {
          this.showWarnMsg("未设置所有级别的有效教师账户有效期");
          return false;
        } else {
          //同步所有级别的老师账号有效期的起始时间
          this.normal.teacherStartDate = this.getPostData(this.normal.time[0]);
          this.normal.teacherEndDate = this.getPostData(this.normal.time[1]);
        }

        //有效学生账户数 程序蛙pid=4,模考pid=5,悟空寻宝pid=9需要判断正式账户学生数
        if (pid == 4 || pid == 5 || pid == 9 || pid == 12) {
          //输入的学生账户数必须大于0
          // if (this.normal.studentNum == 0) {
          //   this.showWarnMsg("输入所有级别的有效学生账户数必须大于0");
          //   return false;
          // }

          if (this.isEmpty(this.normal.studentNum)) {
            this.showWarnMsg("未输入所有级别的有效学生账户数");
            return false;
          }

          //学生账户有效期
          if (this.normal.studentTime.length == 0) {
            this.showWarnMsg("未设置所有级别的有效学生账户有效期");
            return false;
          } else {
            //同步所有级别的老师账号有效期的起始时间
            this.normal.studentStartDate = this.getPostData(this.normal.studentTime[0]);
            this.normal.studentEndDate = this.getPostData(this.normal.studentTime[1]);
          }
        }
      } else if (this.childLevelHasSelected()) {
        //子级别选中
        for (let i = 0; i < this.normal.levelArray.length; i++) {
          let childrenItem = this.normal.levelArray[i];
          if (childrenItem.selected) {
            // if (childrenItem.teacherNum == 0) {
            //   this.showWarnMsg(
            //     `输入${childrenItem.label}的有效教师账户数必须大于0`
            //   );
            //   return false;
            // }

            //有效教师账户数
            if (this.isEmpty(childrenItem.teacherNum)) {
              this.showWarnMsg(`未输入${childrenItem.label}的有效教师账户数`);
              return false;
            }

            //教师账户有效期
            if (childrenItem.time.length == 0) {
              this.showWarnMsg(`未设置${childrenItem.label}有效教师账户的账户有效期`);
              return false;
            } else {
              //同步所有子级别的老师账号有效期的起始时间
              childrenItem.teacherStartDate = this.getPostData(childrenItem.time[0]);
              childrenItem.teacherEndDate = this.getPostData(childrenItem.time[1]);
            }

            //有效学生账户数 程序蛙pid=4,模考pid=5,悟空寻宝pid=9需要判断正式账户学生数
            if (pid == 4 || pid == 5 || pid == 9 || pid == 12) {
              // if (childrenItem.studentNum == 0) {
              //   this.showWarnMsg(
              //     `输入${childrenItem.label}有效学生账户数必须大于0`
              //   );
              //   return false;
              // }

              if (this.isEmpty(childrenItem.studentNum)) {
                this.showWarnMsg(`未输入${childrenItem.label}有效学生账户数`);
                return false;
              }

              //学生账户有效期
              if (childrenItem.studentTime.length == 0) {
                this.showWarnMsg(`未设置${childrenItem.label}有效学生账户有效期`);
                return false;
              } else {
                //同步所有级别的老师账号有效期的起始时间
                childrenItem.studentStartDate = this.getPostData(childrenItem.studentTime[0]);
                childrenItem.studentEndDate = this.getPostData(childrenItem.studentTime[1]);
              }
            }
          }
        }
      }
      return true;
    },

    //子级别是否有选中
    childLevelHasSelected() {
      let res = false;
      for (let i = 0; i < this.normal.levelArray.length; i++) {
        let item = this.normal.levelArray[i];
        if (item.selected) {
          res = true;
          break;
        }
      }
      return res;
    }
  }
};
