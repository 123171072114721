<!-- 编辑试用账户 -->
<template>
  <div class="try_account_edit">
    <div class="margin_top_30 flex_h">
      <i
        v-if="trial.isShow"
        @click.stop="doClick"
        :class="['margin_left_10', 'check_el', 'iconfont', trial.choosed ? 'icon-xuanzhong' : 'icon-danxuanweixuanzhong']"
      ></i>

      <span :class="{ margin_left_10: !trial.isShow }">试用账户</span>

      <!-- 试用老师账户数 -->
      <span class="margin_left_20 tv_red">*</span><span>试用教师账户数：</span>

      <!-- 输入的试用教师账户数  -->
      <el-input
        maxlength="3"
        onkeyup="value=value.replace(/[^\d]/g,'')"
        :disabled="!trial.choosed"
        clearable
        v-model.number="trial.teacherNum"
        placeholder="请输入账户数"
      ></el-input>

      <!-- 使用学生账户数 只有在pid=4程序蛙少儿编程和悟空寻宝-->
      <div v-if="pid == 4 || pid == 9 || pid == 12" class="margin_left_20">
        <!-- 试用学生账户数 -->
        <span class="tv_red">*</span><span>试用学生账户数：</span>

        <!-- 输入的试用学生账户数 -->
        <el-input
          maxlength="3"
          onkeyup="value=value.replace(/[^\d]/g,'')"
          :disabled="!trial.choosed"
          clearable
          v-model.number="trial.studentNum"
          placeholder="请输入账户数"
        ></el-input>
      </div>

      <!-- 试用范围  -->
      <ChooseRange ref="tae" class="margin_left_20" :disableAction="trial.choosed" :chooseData="trial.trialRange"></ChooseRange>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //使用范围的数据
      // trialRange: [
      //     {
      //         label: "第一级别",
      //         id: "1001",
      //         children: [
      //             {
      //                 id: "2001",
      //                 label: "第1单元",
      //             },
      //             {
      //                 id: "2002",
      //                 label: "第2单元",
      //             },
      //         ],
      //     },
      //     {
      //         label: "第二级别",
      //         id: "1002",
      //         children: [
      //             {
      //                 id: "2003",
      //                 label: "第1单元",
      //             },
      //             {
      //                 id: "2004",
      //                 label: "第2单元",
      //             },
      //         ],
      //     },
      // ],
    };
  },
  created() {
    // console.log("传递的试用账户数据是:", this.trial);
  },
  methods: {
    //试用账户的选中和取消事件
    doClick() {
      //改变选中状态
      this.trial.choosed = !this.trial.choosed;
      this.$emit("doChoosedAccount", 2);
    },

    //关闭试用范围的方法
    close() {
      if (!this.isEmpty(this.$refs.tae)) {
        this.$refs.tae.doCancel();
      }
    },
  },
  computed: {},
  components: {},

  //加盟状态以及使用账户数据
  props: ["trial", "pid"],
};
</script>
<style lang="scss" scoped>
.try_account_edit {
  // border-bottom: 1px dashed $line_color;
  padding-bottom: 10px;
  // 选中按钮
  .check_el {
    color: #409eff;
    margin-right: 6px;
    cursor: pointer;
  }

  ::v-deep .el-input__inner {
    height: 28px;
    line-height: 28px;
    padding-left: 6px;
  }

  ::v-deep .el-input__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ::v-deep .el-input {
    width: 120px;
    font-size: 12px;
  }

  ::v-deep .el-input__suffix {
    right: 0px;
  }
}
</style>
